import { Event } from './types';

const queue = (() => {
  const storage: Event[] = []; // this could be local storage / indexed db for offline analytics
  const push = ({ eventName, props, context, eventType = 'track' }: Event): number =>
    storage.push({ eventName, props, context, eventType });
  const remove = () => storage.shift();
  const removeAll = () => {
    storage.length = 0;
    return true;
  };
  const length = () => storage.length;
  const isEmpty = () => !storage.length;
  const getAll = () => storage;
  return {
    push,
    remove,
    length,
    getAll,
    removeAll,
    isEmpty,
  };
})();

export default queue;
