import { Profile } from 'lib/types/profileType';
import { GetAccountRegCode } from 'model/api/GetAccountRegCode';
import { logError } from 'lib/utils/logReportHelper';

interface RegCodeOptions {
  deviceId: string;
  isRegister?: boolean;
  isMVPD?: boolean;
  selectedMvpdId?: string;
}

const getRegCodeResponse = async (
  options: RegCodeOptions,
  profile: Profile,
): Promise<GetAccountRegCode | undefined> => {
  try {
    return await profile?.getAccountRegCode?.(options);
  } catch (error) {
    const responseError = 'Account RegCode Error';
    logError(error, { name: responseError });
    return Promise.reject(error);
  }
};

export default getRegCodeResponse;
