export const trimLowerCase = (value: string | undefined): string =>
  value ? value.replace(/ /g, '').toLowerCase() || '' : '';

export const messageConverter = (
  message: string,
  language_strings_en_US: Record<string, string>,
): string => {
  switch (message) {
    case 'Invalid LoginId':
      return language_strings_en_US?.profile_passwordInvalidError;
    case 'Invalid parameter value':
      return language_strings_en_US?.profileCreate_invalidEmailText;
    default:
      break;
  }
  return message;
};
