import { Profile } from '../types/profileType';
import { GetMvpds } from 'model/api/GetMvpds';
import { logError } from 'lib/utils/logReportHelper';

const getMvpds = async (profile: Profile, val: number): Promise<GetMvpds> => {
  try {
    return await profile?.getMvpds?.({ itemsPerPage: val });
  } catch (error) {
    const responseError = 'Profile get MVPD error';
    logError(error, { name: responseError });
    return Promise.reject(error);
  }
};

export default getMvpds;
