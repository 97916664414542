import { init as initSegment, segmentTrackEvent, flushQueue } from '../analytics/segment';
import { Event, SegmentPayload } from './types';

let initiated = false;
const LCP_TIME = 6000;

export const init = (): boolean => {
  window.segmentFlushQueue = flushQueue;
  if (initiated) return true;
  initSegment();
  initiated = true;
  window.addEventListener('unload', () => {
    flushQueue();
  });
  return initiated;
};

export const handleAnalytics = (event: Event | string): void => {
  segmentTrackEvent(event as SegmentPayload);
};

export const timedOutInit = (): boolean => {
  if (initiated) return true;
  setTimeout(() => init(), LCP_TIME);
  return true;
};
